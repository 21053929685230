<template>
  <Field
    :id="props.id"
    ref="fieldRef"
    v-slot="{ handleChange, errors }"
    v-model="modelValue"
    :type="props.type"
    :name="props.name"
    :rules="props.rules"
  >
    <UiInput
      :id="props.id"
      v-model="modelValue"
      :label="props.label"
      :type="props.type"
      :placeholder="props.placeholder"
      :disabled="props.disabled"
      :label-visible="props.labelVisible"
      :show-error-message="
        props.showValidationMessageInsideInput
          && !props.showValidationMessageOutsideInput
      "
      :errors="errors"
      v-bind="$attrs"
      :variant="props.variant"
      @input="handleChange"
    >
      <template
        v-for="(_, name) in $slots"
        #[name]="slotData"
      >
        <slot
          :name="name"
          v-bind="slotData"
        />
      </template>
    </UiInput>
    <slot
      v-if="
        props.showValidationMessageOutsideInput
          && !props.showValidationMessageInsideInput
      "
      name="error"
      :errors="errors"
    >
      <UiFieldMsg
        :errors="errors"
        :additional-msg="additionalMsg"
      />
    </slot>
  </Field>
</template>

<script setup lang="ts">
import { ref, watch } from 'vue'
import { Field } from 'vee-validate'
import UiFieldMsg from '../UiFieldMsg/UiFieldMsg.vue'
import UiInput from '../UiInput/UiInput.vue'
import type { UiFormField } from '../UiForm.types'

const props = withDefaults(defineProps<UiFormField>(), {
  id: '',
  value: '',
  label: '',
  type: '',
  placeholder: '',
  name: '',
  disabled: false,
  rules: '',
  additionalMsg: '',
  // message placed directly in UiInput
  showValidationMessageInsideInput: false,
  // message displayed in UiFormField, below UiInput
  showValidationMessageOutsideInput: true,
  labelVisible: false,
  variant: 'default',
})

const $emit = defineEmits(['input'])

const fieldRef = ref<InstanceType<typeof Field>>()
defineExpose({
  fieldRef,
})

const modelValue = ref(props.value)
watch(modelValue, (newValue) => {
  const value = newValue
  $emit('input', value)
})
</script>
